<template>
  <span class="emoji-item" @click="insertEmoji(emoji)">
    {{ emoji }}
  </span>
</template>

<script setup lang="ts">
interface Emits {
  (e: "insert-emoji", emoji: string): void;
}
interface Props {
  emoji: string;
}

const emit = defineEmits<Emits>();
defineProps<Props>();

const insertEmoji = (emoji: string) => {
  emit("insert-emoji", emoji);
};
</script>

<style lang="scss" scoped>
@import "./EmojiItem.scss";
</style>
