<template>
  <div
    class="message-wrapper"
    :class="{
      isBlocking: isBlockingTextShown && index === visibleMessages.length - 1,
    }"
  >
    <div v-if="showMessageDate(visibleMessages, index)" class="message-date">
      {{ getMessageDate(message) }}
    </div>
    <div class="message" :class="getIsMyMessageClass(message, authId)">
      <div class="message-content-wrapper">
        <div
          v-if="isMessageContentShown"
          class="message-content"
          :class="[
            getMessageClass(visibleMessages, index, authId),
            { isFriendRequestType },
          ]"
        >
          <slot />
        </div>
        <slot name="imageAttachment" />
        <slot name="videoPlayer" />
      </div>
      <slot name="checkboxSelectMessage" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  showMessageDate,
  getMessageClass,
  getMessageDate,
  getIsMessageTextType,
  getIsMessageFriendRequestType,
} from "@/utils/message";
import { getIsMyMessageClass } from "@/utils/chat";
import { Message } from "@/store/chats/types";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { computed } from "vue";
import { getIsBlockingTextShown } from "@/utils/chat";

interface ChatMessageContentProps {
  message: Message;
  index: number;
  visibleMessages: Message[];
}

const props = defineProps<ChatMessageContentProps>();
const isBlockingTextShown = getIsBlockingTextShown();
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);

const isMessageContentShown = computed<boolean>(() => {
  if (!getIsMessageTextType(props.message)) {
    return true;
  }

  return Boolean(
    props.message.messageText ||
      props.message?.othersAttachments ||
      props.message?.imageAttachments?.length === 1,
  );
});

const isFriendRequestType = computed(() =>
  getIsMessageFriendRequestType(props.message),
);
</script>

<style lang="scss">
@import "./ChatMessageContent.scss";
</style>
