export default {
  "call": {
    "status": {
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished call"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missed call"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing call…"])}
    },
    "type": {
      "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing call"])}
    },
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join in"])},
    "isCallingYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is calling you"])},
    "establishingCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishing connection with"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you"])}
  },
  "popup": {
    "PopupCancelButton": {
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "PopupEditGroup": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name and image"])},
      "optionPicture": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture of the group"])},
        "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
        "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture of the group"])}
      },
      "optionName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name"])}
      },
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])}
    },
    "PopupEditGroupMembers": {
      "headerMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group members"])},
      "headerAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])}
    },
    "PopupInfoDeleteChat": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the chat"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this chat? Messages from the chat will be permanently deleted, as well as any attachments that the chat contains. Other people in the chat will still be able to view it."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete permanently"])}
    },
    "PopupInfoDeleteMessages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete selected messages"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you choose to delete selected messages, they will be permanently deleted from your device. But other people in the chat will still see them."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select messages to delete"])}
    },
    "PopupInfoLeaveGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave group"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave the group? This action is irreversible. The group can only be re-entered by invitation from their members."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave group"])}
    },
    "PopupMuteChat": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute notifications"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will no longer receive notifications from this chat. You can switch them back on in the context menu. Do you want to continue?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute notifications"])}
    },
    "PopupNotificationsSetting": {
      "optionSound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sounds"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "optionNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification on the desktop"])},
      "optionPreviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show previews"])},
      "optionNoNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not show any notifications on the desktop"])},
      "optionRing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtone for incoming call"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save settings"])}
    },
    "PopupSelectFriendToChat": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends selection"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save "])}
    },
    "PopupChatDesign": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance"])},
      "optionColorful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colorful"])},
      "optionClassic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classic"])},
      "optionDarkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark mode"])},
      "optionMinimalist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalistic"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    },
    "PopupInfoDeleteUserAccount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete your account? This action is irreversible and you will no longer be able to log in to your account."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete permanently"])},
      "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, I want to keep the account."])}
    },
    "PopupInfoReportUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report user"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to report the user?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
    },
    "PopupInfoBlockUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block user"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to block this user?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block permanently"])}
    },
    "PopupInfoUnblockUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock user"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to unblock this user?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock"])}
    },
    "PopupChatAppearance": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance"])},
      "optionColorful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colorful"])},
      "optionClassic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classic"])},
      "optionDarkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark mode"])},
      "optionMinimalist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalist"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save settings"])}
    }
  },
  "cards": {
    "SidebarOptionsCard": {
      "optionArchives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
      "optionNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
      "optionSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])}
    },
    "OneToOneChatOptionsCard": {
      "optionCloseChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the chat"])},
      "optionMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute notifications"])},
      "optionDeleteMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete messages"])},
      "optionDeleteChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the chat"])},
      "optionchatDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance"])},
      "optionReportUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report user"])},
      "optionBlockUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block user"])},
      "optionUnblockUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock user"])},
      "optionChatAppearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance"])}
    },
    "GroupChatOptionsCard": {
      "optionChatSetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat settings"])},
      "optionGroupMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group members"])},
      "optionLeaveGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave group"])},
      "optionMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute notifications"])},
      "optionCloseChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the chat"])},
      "optionDeleteMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete messages"])},
      "optionDeleteChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the chat"])},
      "optionchatDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance"])},
      "optionChatAppearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance"])}
    }
  },
  "chat": {
    "chatContainer": {
      "NoChatContainer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the MAIT chat."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start texting with your friends or create a group chat."])}
      },
      "InputBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a message..."])}
      },
      "ChatTopBarNewChat": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To:"])}
      },
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
      "VideoPlayer": {
        "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video is being processed"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while processing the video"])}
      },
      "NewChatListContainer": {
        "yourContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your contacts that use MAIT Chat"])},
        "otherPeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other people at MAIT"])},
        "recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommend MAIT Chat"])}
      },
      "messages": {
        "audioMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["📣 Voice message"])}
      }
    },
    "chatSidebar": {
      "ChatSidebarTopBar": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My conversations"])}
      },
      "SearchBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for friends or a group"])}
      },
      "NewChatPreview": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New conversation"])}
      },
      "ChatPreview": {
        "removeMessageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has deleted the message."])}
      },
      "searchBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for friends or a group"])}
      }
    },
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "blockingStatus": {
      "hasBlockedYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blocked you"])},
      "youHaveBlockedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have blocked this user"])},
      "unblockToContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock the user to continue the conversation."])},
      "youCannotContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation cannot be continued."])}
    },
    "hasDeletedAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleted the account."])}
  },
  "select": {
    "UsersSelectBox": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Contact"])}
    },
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "inviteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's connect via MAIT Chat! It's a fast, simple and secure app with free messaging and calling. Download it at https://mait.me/download."])}
  },
  "date": {
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])}
  },
  "user": {
    "i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me"])},
    "deletedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted user"])},
    "anonymousUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous user"])}
  },
  "buttons": {
    "TopBarDeletingMessagesButtons": {
      "cancelDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard deleting"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete selected"])}
    }
  },
  "auth": {
    "buttons": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "loginWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with your phone number"])},
      "loginWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with your email"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
      "continueToApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to the application"])},
      "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login"])},
      "sendLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an e-mail with a link"])},
      "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore password"])},
      "sendVerificationSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send verification SMS"])},
      "resendVerificationSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send verification SMS again"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "restoreOrCreate": {
      "cantLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't log in? Try"])},
      "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restore password"])},
      "orCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or create"])},
      "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new account"])}
    },
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "welcomeToMAIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to MAIT Chat!"])},
    "enterRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need a working email address and password to enter."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you already have an account?"])},
    "thankYouForRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for registration!"])},
    "thankYouForRegistrationContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can start chatting with your friends from anywhere without hesitation and without fear. The chat is fully secure and we keep your messages to ourselves."])},
    "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore password"])},
    "restorePasswordInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email account and we will send you a link to create a new password."])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "newPasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password again"])},
    "businessConditions": {
      "iAgreeWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree with"])},
      "gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data Processing Policy"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
      "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])}
    },
    "errors": {
      "wrongCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect login credentials."])},
      "userAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account already exists."])},
      "agreeToTermsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to agree to the terms."])},
      "thisFieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
      "invalidPasswordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters long"])},
      "invalidEmailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email format."])}
    }
  },
  "snackbar": {
    "errors": {
      "userAlreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account already exists."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue on the login screen."])}
      },
      "agreeToTermsAndConditions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing consent."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, you cannot register without agreeing to the Personal Data Processing Policy and Terms of Use."])}
      },
      "wrongCredentials": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login error."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The username or password was entered incorrectly. Please try again or reset your password."])}
      },
      "accountWasClosedLogin": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account has been closed"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account you are trying to log in to has been closed. You can request that it be reinstated by emailing support", "@", "mait.me. Thank you for your understanding."])}
      },
      "accountWasClosedRegister": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account has been closed"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account already exists but has been closed. You can request its renewal by emailing support", "@", "mait.me. Thank you for your understanding."])}
      },
      "invalidSmsCode": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SMS code does not match."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try entering the security code again."])}
      },
      "invalidPhoneNumber": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid phone number."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try entering the phone number again."])}
      },
      "defaultError": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again."])}
      },
      "tooManyAttempts": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many attempts"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another verification code cannot be sent at this time. Please try again later."])}
      },
      "phoneNumberAlreadyInUse": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number cannot be connected."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This phone number is already in use by another user."])}
      },
      "videoWasNotUploaded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video upload failed."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again."])}
      }
    },
    "notifications": {
      "thankYouForReportingUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for reporting the user."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team will now investigate the report."])}
      },
      "yourAccountWasDeleted": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been deleted."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We wish you all the best."])}
      },
      "theUserWasBlocked": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been blocked."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not receive any further messages."])}
      },
      "theUserWasUnblocked": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been unblocked."])}
      },
      "theChatWasDeleted": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The conversation has been deleted."])}
      },
      "youHaveLeftTheGroupConversation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The conversation was abandoned."])}
      }
    }
  },
  "welcome": {
    "welcomeInMait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to MAIT Chat!"])},
    "loginWithMaitAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with MAIT account"])},
    "loginWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with phone number"])},
    "noAccountYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't you have an account yet?"])},
    "inApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the app."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "loginWithCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your MAIT account details to log in to the app."])},
    "unsuccessfulLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't log in? Try "])},
    "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restore password."])},
    "loginWithPhoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your phone number to log in. We will then send you an authentication code."])},
    "confirmLoginWithPhoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After entering the verification code we just sent you in a text message, you can log in."])},
    "sendCodeToPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send verification SMS"])}
  },
  "forms": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchTenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Tenor"])}
  },
  "settings": {
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
    "personalDetailsContent": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "saveDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and surname"])}
    },
    "connectPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your phone number"])},
    "connectPhoneNumberContent": {
      "yourPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number"])},
      "savePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save phone number"])},
      "deletePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete phone number"])},
      "confirmation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To verify, use the verification code we just sent you in a text message."])}
      },
      "connectNewPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect a new phone number"])}
    },
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "notificationsContent": {
      "sounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sounds"])},
      "hideNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show any notifications"])},
      "notificationBarNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications in the notification bar"])},
      "incomingCallRing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringing for an incoming call"])},
      "switch": {
        "unused": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So you won't miss anything..."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enable notifications. Without them, the app won't be able to send you notifications about what's new in the app."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable notifications"])},
          "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue without enabling"])}
        },
        "enabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications are already enabled."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you won't miss anything. Don't worry! You can easily disable notifications in your phone settings if notifications disturb you."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Settings"])}
        },
        "disabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications are disabled."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To get new notifications, go to system settings. In the individual apps section, find MAIT Chat and enable notifications."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Settings"])}
        }
      }
    },
    "transferContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer contacts"])},
    "transferContactsContent": {
      "switch": {
        "unused": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your phone contacts."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIT Chat accesses your contact list and sends phone numbers to mait.me to search for existing contacts in the MAIT network. The contact list is accessed whenever the application is opened. Phone numbers are not being stored on mait.me. Do you agree to grant access?"])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree"])},
          "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not now"])}
        },
        "enabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your contacts are already connected."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like you've transferred your contacts before. You can find all your linked contacts when you create a new conversation."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Settings"])}
        },
        "disabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your contacts are not connected."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To link your contacts, go to the system settings. In the individual apps section, find MAIT Chat and enable contact transfer."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Settings"])}
        }
      }
    },
    "profilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile picture"])},
    "profilePictureContent": {
      "changeProfilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change profile picture"])},
      "deleteProfilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete profile picture"])}
    },
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "activateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate your MAIT account"])},
    "activateAccountContent": {
      "activationOfAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIT account activation"])}
    },
    "deleteAccountContent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are very sorry to see you go."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to cancel the account? This action is non-refundable and you will not be able to log in to your account."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, I want to keep the account."])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I want to cancel my account."])}
    },
    "changeLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change language"])},
    "changeLanguageContent": {
      "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česky"])},
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovensky"])},
      "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Український"])}
    },
    "reportError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report bug"])},
    "reportErrorContent": {
      "messageFromApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message from the app"])}
    },
    "termsAndConditions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])}
    },
    "chat": {
      "blockUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block user"])},
        "blockingMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocking messages"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will no longer be able to write or receive messages from this chat. However, you can still communicate in group conversations."])}
        },
        "blockCanBeReverted": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can cancel the blocking at any time"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can cancel the blocking at any time in the conversation settings."])}
        }
      },
      "createGroupChat": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a group chat"])},
        "contentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New group"])},
        "usersRecommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User suggestions"])},
        "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])}
      },
      "unblockUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock user"])},
        "unblockingMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblocking messages"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once unblocked, you'll be able to send messages, photos or files to each other again."])}
        },
        "secondaryButton": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue blocking"])}
        }
      },
      "reportUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a conversation"])},
        "foundInappropriateContent": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you come across inappropriate content?"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are certain that a user's messages are harassing, inappropriate or dangerous, please let us know."])}
        },
        "weValueYourSafety": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We respect your safety"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user will not know who reported it. So you don't have to worry about the consequences."])}
        }
      },
      "deleteChat": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete conversation"])},
        "deleteAllMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all messages"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irreversibly delete all messages. Therefore, think carefully if you really want to take this action."])}
        },
        "photosAndFiles": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos and files"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep in mind that you may lose pictures, videos or documents along with your messages."])}
        }
      },
      "leaveGroupConversation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave group"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave conversation"])},
        "leavingConversation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaving the conversation"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you leave a conversation, you can only return by invitation from group members."])}
        },
        "yourMessagesRemain": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your messages continue to remain"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages you sent before you left the group will remain in the group after you leave."])}
        }
      },
      "renameGroup": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change chat name"])},
        "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat name"])}
      },
      "showUsers": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show users"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group members"])},
        "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
        "options": {
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
        },
        "redirectModal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave?"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have unsaved changes in the group members list that will be lost."])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes and leave"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave without saving"])}
        }
      },
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with"])}
    }
  },
  "onboarding": {
    "welcome": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to MAIT Chat!"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before we start, we would need your permission for some services in order for the application to work properly."])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's begin"])}
    }
  },
  "countryCodes": {
    "afghanistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "algeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algeria"])},
    "samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["American Samoa"])},
    "the american virgin islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U.S. Virgin Islands"])},
    "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "angola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "antigua and barbuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua and Barbuda"])},
    "anguilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "argentina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "armenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "aruba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "ascension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascension Island"])},
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "australian territories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territories of Australia"])},
    "azerbaijan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
    "bahrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
    "bahamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "bangladesh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "barbados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
    "belize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "belarus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "benin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "bermuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
    "bhutan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
    "bolivia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "bosnia and herzegovina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia and Herzegovina"])},
    "botswana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "brazil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
    "virgin islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Virgin Islands"])},
    "brune": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "burkina faso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "burundi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "cook islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cook Islands"])},
    "cad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
    "montenegro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "czech republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech Republic"])},
    "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
    "democratic republic of congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Democratic Republic of the Congo"])},
    "diego garcia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diego Garcia"])},
    "dominica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "dominican republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominican Republic"])},
    "djibout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
    "ecuador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "eritrea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "ethiopia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopia"])},
    "faroe islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faroe Islands"])},
    "fiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "philippines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
    "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "french guiana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Guiana"])},
    "french polynesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Polynesia"])},
    "gabon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "gambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of The Gambia"])},
    "ghana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "gibraltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "grenada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "greenland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenland"])},
    "georgia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "guadeloupe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "guam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "guatemala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "guinea-bissau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "guyana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "haiti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "honduras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "hong kong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong"])},
    "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "iraq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
    "iran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
    "iceland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iceland"])},
    "italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
    "israel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "jamaica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "south african republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Africa"])},
    "south korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Korea"])},
    "cayman islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cayman Islands"])},
    "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
    "cambodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodia"])},
    "cameroon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroon"])},
    "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "kazakhstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "kenya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "kiribati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo"])},
    "costa rica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "cuba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "kuwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuwait"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "kyrgyzstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstan"])},
    "laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "lesotho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "lebanon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebanon"])},
    "liberia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "libya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
    "lichtenstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "lithuanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
    "latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
    "luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "madagascar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
    "macedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedonia"])},
    "malaysia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "malawi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "maldives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "mali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "morocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morocco"])},
    "marshall islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of the Marshall Islands"])},
    "martinik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
    "mauritius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "mauritania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "mexico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
    "moldova": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "micronésia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
    "monaco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "mongolia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
    "montserrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "mosambique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "myanmar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar"])},
    "namibia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "nura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "nepal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "niger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "nicaragua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "nigeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "niue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "netherlands antilles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands Antilles"])},
    "netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
    "norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])},
    "new caledonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Caledonia"])},
    "oman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "new zealand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
    "pakistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "palau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "palestine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of Palestine"])},
    "panama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "paraguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "ivory coast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côte d’Ivoire"])},
    "poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
    "puerto rico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Rico"])},
    "portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "equatorial guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equatorial Guinea"])},
    "romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
    "rwanda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "saint pierre and miquelon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre and Miquelon"])},
    "greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
    "salvador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "san marino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "saudi arabia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudi Arabia"])},
    "senegal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "north korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Korea"])},
    "seychelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "sierra leone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
    "slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "somalia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "united arab emirates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Arab Emirates"])},
    "united kingdom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
    "serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "sri lanka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "central african republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central African Republic"])},
    "sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "saint helena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Helena"])},
    "saint lucia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
    "saint kitts and nevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts and Nevis"])},
    "saint thomas and prince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Thomas and Prince's Island"])},
    "saint vincent and the grenadines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent and the Grenadines"])},
    "syria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syria"])},
    "solomon islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon Islands"])},
    "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
    "sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
    "switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
    "tajikistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
    "tanzania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "thailand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
    "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "togo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "tonga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "trinidad and tobago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad and Tobago"])},
    "tunisia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
    "turkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkey"])},
    "turkmenistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "tuvalu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "uganda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "uruguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "uzbekistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "vanuatu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "venezuela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "vietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "east timor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["East Timor"])},
    "wallis and futuna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis and Futuna"])},
    "zambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "zimbabwe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])},
    "american samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["American Samoa"])},
    "british indian ocean territory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Indian Ocean Territory"])},
    "falkland islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falkland Islands"])},
    "guernsey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "isle of man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle of Man"])},
    "jersey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "cape verde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cape Verde"])},
    "qatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "christmas island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Christmas Island"])},
    "cocos island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coconut Island"])},
    "kosovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "comoros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoros Islands"])},
    "macau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau"])},
    "mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "papua new guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua New Guinea"])},
    "pitcairn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairn"])},
    "reunion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "russia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russia"])},
    "northern mariana islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Northern Mariana Islands"])},
    "south sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Sudan"])},
    "suriname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "saint barthelemy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Bartholomew"])},
    "saint martin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Martin"])},
    "swaziland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
    "svalbard and jan mayen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard and Jan Mayen"])},
    "tokelau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "turks and caicos islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks and Caicos"])},
    "united states of america": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States of America"])},
    "vatican": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatican City"])},
    "western sahara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Western Sahara"])},
    "falkland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falkland Islands"])},
    "capsule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cape Verde"])},
    "catarrh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "chamber and mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Union of the Comoros"])},
    "macao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau"])},
    "papua-nova guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua New Guinea"])},
    "réunion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunion"])},
    "northern marian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Northern Mariana Islands"])},
    "surinam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "savilia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
    "tokelu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "turks and caicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks and Caicos Islands"])}
  },
  "versions": {
    "whatIsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's new in MAIT Chat"])},
    "registrationOverPhone": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration via phone number"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick registration without unnecessary delays. Now you only need a phone number to register for MAIT."])}
    },
    "communicationWithPhoneContacts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communicating with mobile contacts"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep in touch with friends you have saved on your phone who don't have a MAIT account."])}
    },
    "improvedVideoDisplaying": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improved video display"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch conveniently recorded videos. We've added a fullscreen mode for both landscape and portrait videos."])}
    },
    "callWithYourFriends": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call with your friends"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIT Chat now supports audio and video calls with your friends on the MAIT network."])}
    },
    "groupCalls": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group calls and video calls"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now also make calls in MAIT Chat as part of group conversations."])}
    },
    "improvementsAndFixes": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhancements and fixes"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under the hood, we've fixed a number of bugs and added various improvements for a better experience."])}
    }
  },
  "message-left-sidebar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats"])},
    "search-bar-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])}
  },
  "message-header": {
    "header-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])}
  },
  "user-list": {
    "multiselect-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])}
  },
  "share": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])}
  }
}