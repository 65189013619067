export default {
  "call": {
    "status": {
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proběhlý hovor"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřijatý hovor"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhající hovor…"])}
    },
    "type": {
      "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odchozí hovor"])}
    },
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojit se"])},
    "isCallingYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volá vám"])},
    "establishingCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navazování hovoru s"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vy"])}
  },
  "popup": {
    "PopupCancelButton": {
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])}
    },
    "PopupEditGroup": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název a obrázek skupiny"])},
      "optionPicture": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrázek skupiny"])},
        "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit"])},
        "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrázek skupiny"])}
      },
      "optionName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název skupiny"])}
      },
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit změny"])}
    },
    "PopupEditGroupMembers": {
      "headerMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členové skupiny"])},
      "headerAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přidat"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit změny"])}
    },
    "PopupInfoDeleteChat": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat chat"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete smazat tento chat? Zprávy z chatu budou nenávratně odstraněny, stejně jako veškeré přílohy, které chat obsahuje. Ostatní osoby v chatu jej budou moci nadále zobrazit."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trvale smazat"])}
    },
    "PopupInfoDeleteMessages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat vybrané zprávy"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se rozhodnete smazat vybrané zprávy, nenávratně je smažete ze svého zařízení. Ostatní osoby v chatu je ale stále uvidí."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat zprávy ke smazání"])}
    },
    "PopupInfoLeaveGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustit skupinu"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete opustit skupinu? Tato akce je nevratná. Do skupiny se lze opět dostat jen pozvánkou jejich členy."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustit skupinu"])}
    },
    "PopupMuteChat": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ztlumit oznámení"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohoto chatu Vám již nebudou přicházet žádná oznámení. V kontextové nabídce si je můžete opět zapnout. Chcete pokračovat?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ztlumit oznámení"])}
    },
    "PopupNotificationsSetting": {
      "optionSound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvuky"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
      "optionNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení na ploše"])},
      "optionPreviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit náhledy"])},
      "optionNoNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezobrazovat žádná oznámení na ploše"])},
      "optionRing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzvánění pro příchozí hovor"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit nastavení"])}
    },
    "PopupSelectFriendToChat": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr přátel"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit "])}
    },
    "PopupChatDesign": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhled chatu"])},
      "optionColorful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barevný"])},
      "optionClassic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasický"])},
      "optionDarkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tmavý režim"])},
      "optionMinimalist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalistický"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit nastavení"])}
    },
    "PopupInfoDeleteUserAccount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení účtu"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete zrušit účet? Tato akce je nevratná a ke svému účtu už se nebude moct přihlásit."])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano, chci zrušit účet."])},
      "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne, chci účet zachovat."])}
    },
    "PopupInfoReportUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásit uživatele"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu nahlásit uživatele?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásit"])}
    },
    "PopupInfoBlockUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zablokovat uživatele"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu zablokovat uživatele?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trvale zablokovat"])}
    },
    "PopupInfoUnblockUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokovat uživatele"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu odblokovat uživatele?"])},
      "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokovat"])}
    },
    "PopupChatAppearance": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhled chatu"])},
      "optionColorful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barevný"])},
      "optionClassic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasický"])},
      "optionDarkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tmavý režim"])},
      "optionMinimalist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalistický"])},
      "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit nastavení"])}
    }
  },
  "cards": {
    "SidebarOptionsCard": {
      "optionArchives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiované"])},
      "optionNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
      "optionSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabezpečení"])}
    },
    "OneToOneChatOptionsCard": {
      "optionCloseChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít chat"])},
      "optionMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ztlumit oznámení"])},
      "optionDeleteMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat zprávy"])},
      "optionDeleteChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit konverzaci"])},
      "optionchatDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhled chatu"])},
      "optionReportUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásit uživatele"])},
      "optionBlockUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zablokovat uživatele"])},
      "optionUnblockUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokovat uživatele"])},
      "optionChatAppearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhled chatu"])}
    },
    "GroupChatOptionsCard": {
      "optionChatSetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení chatu"])},
      "optionGroupMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členové skupiny"])},
      "optionLeaveGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustit skupinu"])},
      "optionMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ztlumit oznámení"])},
      "optionCloseChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít chat"])},
      "optionDeleteMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat zprávy"])},
      "optionDeleteChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit chat"])},
      "optionchatDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhled chatu"])},
      "optionChatAppearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhled chatu"])}
    }
  },
  "chat": {
    "chatContainer": {
      "NoChatContainer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vítejte v chatu MAIT."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začněte si psát se svými přáteli nebo vytvořte skupinový chat."])}
      },
      "InputBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte zprávu..."])}
      },
      "ChatTopBarNewChat": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komu:"])}
      },
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přečteno"])},
      "VideoPlayer": {
        "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhá zpracování videa"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při zpracování videa došlo k chybě"])}
      },
      "NewChatListContainer": {
        "yourContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše kontakty, které používají MAIT Chat"])},
        "otherPeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další lidé na MAIT"])},
        "recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doporučit MAIT Chat"])}
      },
      "messages": {
        "audioMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["📣 Hlasová zpráva"])}
      }
    },
    "chatSidebar": {
      "ChatSidebarTopBar": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje konverzace"])}
      },
      "SearchBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte přátele nebo skupinu"])}
      },
      "NewChatPreview": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová konverzace"])}
      },
      "EditChatPreview": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do skupiny"])}
      },
      "ChatPreview": {
        "removeMessageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel zprávu odstranil."])}
      },
      "searchBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte přátelé nebo skupinu"])}
      }
    },
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příloha"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel"])},
    "blockingStatus": {
      "hasBlockedYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si vás zablokoval"])},
      "youHaveBlockedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zablokoval jste uživatele"])},
      "unblockToContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro pokračování v konverzaci uživatele odblokujte."])},
      "youCannotContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na tuto konverzaci nelze navázat."])}
    },
    "hasDeletedAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smazal účet."])}
  },
  "select": {
    "UsersSelectBox": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte Kontakt"])}
    },
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvat"])},
    "inviteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojme se pres MAIT Chat! Je to rychla, jednoducha a bezpecna aplikace s bezplatnym posilanim zprav i volanim. Ke stazeni na https://mait.me/download."])}
  },
  "date": {
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dnes"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Včera"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úterý"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondělí"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Středa"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtek"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pátek"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neděle"])}
  },
  "user": {
    "i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já"])},
    "deletedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazaný uživatel"])},
    "anonymousUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymní uživatel"])}
  },
  "buttons": {
    "TopBarDeletingMessagesButtons": {
      "cancelDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit mazání"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat vybrané"])}
    }
  },
  "auth": {
    "buttons": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
      "loginWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se telefonním číslem"])},
      "loginWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se emailem"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrovat se"])},
      "continueToApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat do aplikace"])},
      "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na přihlášení"])},
      "sendLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaslat e-mail s odkazem"])},
      "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit heslo"])},
      "sendVerificationSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaslat ověřovací SMS"])},
      "resendVerificationSms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaslat ověřovací SMS znovu"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nebo"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo"])},
    "restoreOrCreate": {
      "cantLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejde se přihlásit? Zkus"])},
      "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obnovit heslo"])},
      "orCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nebo si vytvořit"])},
      "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nový účet"])}
    },
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrace"])},
    "welcomeToMAIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vítej v chatu MAIT!"])},
    "enterRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro vstup potřebuješ funkční emailovou adresu a heslo."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Už máš u nás účet?"])},
    "thankYouForRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme za registraci!"])},
    "thankYouForRegistrationContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní můžeš bez váhání začít chatovat se svými přáteli odkudkoliv a beze strachu. Chat je zabezpečený plně zabezpečený a vaše zprávy si necháváme pro sebe."])},
    "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnova hesla"])},
    "restorePasswordInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadej emailový účet, na který ti pošleme odkaz pro vytvoření nového hesla."])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo"])},
    "newPasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo znovu"])},
    "businessConditions": {
      "iAgreeWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlasím se"])},
      "gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zásadami zpracování osobních údajů"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provozními podmínkami"])},
      "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])}
    },
    "errors": {
      "wrongCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávné přihlašovací údaje."])},
      "userAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento účet již existuje."])},
      "agreeToTermsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je třeba souhlasit s podmínkami."])},
      "thisFieldIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné."])},
      "invalidPasswordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo musí mít alespoň 8 znaků"])},
      "invalidEmailFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatný formát e-mailu."])}
    }
  },
  "snackbar": {
    "errors": {
      "userAlreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento účet již existuje."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračujte na přihlašovací obrazovce."])}
      },
      "agreeToTermsAndConditions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chybějící souhlas."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez souhlasu se Zásadami pro zpracování osobních údajů a Provozními podmínkami bohužel nelze provést registrace."])}
      },
      "wrongCredentials": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba při přihlášení."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelské jméno nebo heslo byly zadány špatně. Zkuste to znovu nebo obnovte heslo."])}
      },
      "accountWasClosedLogin": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet byl uzavřen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet, ke kterému se snažíte přihlásit, byl uzavřen. O jeho obnovení můžete požádat emailem na adrese support", "@", "mait.me. Děkujeme za pochopení."])}
      },
      "accountWasClosedRegister": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet byl uzavřen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento účet již existuje, ale byl uzavřen. O jeho obnovení můžete požádat emailem na adrese support", "@", "mait.me. Děkujeme za pochopení."])}
      },
      "invalidSmsCode": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS kód se neshoduje."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste zadat bezpečnostní kód ještě jednou."])}
      },
      "invalidPhoneNumber": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatné telefonní číslo."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste zadat telefonní číslo ještě jednou."])}
      },
      "defaultError": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Došlo k chybě"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste to znovu."])}
      },
      "tooManyAttempts": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příliš mnoho pokusů"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další ověřovací kód momentálně nelze odeslat. Prosím, zkuste to později."])}
      },
      "phoneNumberAlreadyInUse": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo nelze napojit."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto telefonní číslo již používá jiný uživatel."])}
      },
      "videoWasNotUploaded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrání videa selhalo."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím, zkuste to znovu."])}
      }
    },
    "notifications": {
      "thankYouForReportingUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme za nahlášení uživatele."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náš tým nyní nahlášení prověří."])}
      },
      "yourAccountWasDeleted": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš účet byl smazán."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejeme vše dobré."])}
      },
      "theUserWasBlocked": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel byl zablokován."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné další zprávy vám nepřijdou."])}
      },
      "theUserWasUnblocked": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel byl odblokován."])}
      },
      "theChatWasDeleted": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace byla smazána."])}
      },
      "youHaveLeftTheGroupConversation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace byla opuštěna."])}
      }
    }
  },
  "welcome": {
    "welcomeInMait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vítej v chatu MAIT!"])},
    "loginWithMaitAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se MAIT účtem"])},
    "loginWithPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se telefonním číslem"])},
    "noAccountYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ještě nemáš účet?"])},
    "inApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do aplikace."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registruj se"])},
    "loginWithCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro přihlášení do aplikace použij údaje ke svému účtu MAIT."])},
    "unsuccessfulLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedaří se přihlásit? Zkuste "])},
    "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obnovit heslo."])},
    "loginWithPhoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro přihlášení použij své telefonní číslo. Následně ti na něj zašleme ověřovací kód."])},
    "confirmLoginWithPhoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po zadání ověřovacího kódu, který jsme ti právě zaslali v SMS, se můžeš přihlásit."])},
    "sendCodeToPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaslat ověřovací SMS"])}
  },
  "forms": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
    "searchTenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledejte v databázi Tenor"])}
  },
  "settings": {
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní údaje"])},
    "personalDetailsContent": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení"])},
      "saveDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit údaje"])},
      "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit účet"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a příjmení"])}
    },
    "connectPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napojit telefonní číslo"])},
    "connectPhoneNumberContent": {
      "yourPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše telefonní číslo"])},
      "savePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit telefonní číslo"])},
      "deletePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit telefonní číslo"])},
      "confirmation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověření"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro ověření použij ověřovací kód, který jsme ti právě zaslali v SMS."])}
      },
      "connectNewPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napojit nové telefonní číslo"])}
    },
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozornění"])},
    "notificationsContent": {
      "sounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvuky"])},
      "hideNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezobrazovat žádná oznámení"])},
      "notificationBarNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení v notifikační liště"])},
      "incomingCallRing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzvánění pro příchozí hovor"])},
      "switch": {
        "unused": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby vám nic neuniklo..."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosíme, povolte notifikace. Bez nich se vám aplikace nebude moct posílat oznámení o tom, co se v ní událo nového."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit notifikace"])},
          "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat bez povolení"])}
        },
        "enabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikace už jsou povoleny."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teď už vám nic neunikne. Žádný strach! Pokud by vás notifikace rušily, zakážete je snadno v nastavení telefonu."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět do nastavení"])}
        },
        "disabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikace nejsou povoleny."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abyste dosáhli zobrazování nových notifikací, jděte do systémového nastavení. V sekci jednotlivých aplikací najděte MAIT Chat a povolte notifikace."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět do nastavení"])}
        }
      }
    },
    "transferContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přenést kontakty"])},
    "transferContactsContent": {
      "switch": {
        "unused": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojte kontakty z telefonu."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIT Chat přistupuje k seznamu kontaktů a odesílá telefonní čísla na server mait.me, aby mohla vyhledávat již existující kontakty v síti MAIT. K seznamu kontaktů je přistupováno vždy při otevření aplikace. Telefonní čísla nejsou na serveru mait.me ukládána. Souhlasíte s udělením přístupu?"])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlasím"])},
          "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní ne"])}
        },
        "enabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše kontakty jsou již propojeny."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypadá to, že jste si kontakty přenesli již dříve. Všechny své propojené kontakty najdete při vytváření nové konverzace."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět do nastavení"])}
        },
        "disabled": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše kontakty nejsou propojeny."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abyste dosáhli propojení kontaktů, jděte do systémového nastavení. V sekci jednotlivých aplikací najděte MAIT Chat a povolte přenos kontaktů."])},
          "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět do nastavení"])}
        }
      }
    },
    "profilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilový obrázek"])},
    "profilePictureContent": {
      "changeProfilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit profilový obrázek"])},
      "deleteProfilePicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit profilový obrázek"])}
    },
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])},
    "activateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivovat účet MAIT"])},
    "activateAccountContent": {
      "activationOfAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivace účtu MAIT"])}
    },
    "deleteAccountContent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moc nás mrzí, že odcházíte."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete zrušit účet? Tato akce je nevratná a ke svému účtu už se nebude moct přihlásit."])},
      "primaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne, chci účet zachovat."])},
      "secondaryButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano, chci zrušit účet."])}
    },
    "changeLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna jazyka"])},
    "changeLanguageContent": {
      "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česky"])},
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovensky"])},
      "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["український"])}
    },
    "reportError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásit chybu"])},
    "reportErrorContent": {
      "messageFromApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva z aplikace"])}
    },
    "termsAndConditions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smluvní podmínky"])},
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracování osobních údajů"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provozní podmínky"])}
    },
    "chat": {
      "blockUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokovat uživatele"])},
        "blockingMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokování zpráv"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohoto chatu již nebudete moci psát ani přijímat zprávy. Ve skupinových konverzacích ale spolu stále můžete komunikovat."])}
        },
        "blockCanBeReverted": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokování můžete kdykoli zrušit"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V nastavení konverzace můžete blokování kdykoli zrušit."])}
        }
      },
      "createGroupChat": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit skupinový chat"])},
        "contentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová skupina"])},
        "usersRecommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrhy uživatelů"])},
        "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit"])}
      },
      "editGroupChat": {
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])}
      },
      "unblockUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokovat uživatele"])},
        "unblockingMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokování zpráv"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po odblokování budete mezi sebou opět moci posílat zprávy, fotografie nebo soubory."])}
        },
        "secondaryButton": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat v blokování"])}
        }
      },
      "reportUser": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásit konverzaci"])},
        "foundInappropriateContent": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narazili jste na nevhodný obsah?"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud jste si jistí, že zprávy od uživatele jsou obtěžující, nevhodné nebo nebezpečné, dejte nám tímto vědět."])}
        },
        "weValueYourSafety": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ctíme vaši bezpečnost"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel se nedozví, kdo jej nahlásil. Nemusíte se tedy bát následků."])}
        }
      },
      "deleteChat": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit konverzaci"])},
        "deleteAllMessages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranění veškerých zpráv"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevratně smažete veškeré zprávy. Proto si dobře rozmyslete, jestli tuto akci opravdu chcete provést."])}
        },
        "photosAndFiles": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotografie a soubory"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mějte na paměti, že spolu se zprávami přijdete i o obrázky, videa nebo dokumenty."])}
        }
      },
      "leaveGroupConversation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustit skupinu"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustit konverzaci"])},
        "leavingConversation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opuštění konverzace"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Když opustíte konverzaci, můžete se vrátit jen na základě pozvánkou od členů skupiny."])}
        },
        "yourMessagesRemain": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše zprávy nadále zůstávají"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zprávy, které jste odeslal před opuštěním skupiny v ní nadále zůstanou i po vašem odchodu."])}
        }
      },
      "renameGroup": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit název chatu"])},
        "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název chatu"])}
      },
      "showUsers": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit členy skupiny"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členové skupiny"])},
        "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přidat"])},
        "addUsersToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat členy do skupiny"])},
        "options": {
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit změny"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])}
        },
        "redirectModal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete odejít?"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V seznamu členů skupiny máte neuložené změny, které budou ztraceny."])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit změny a odejít"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odejít bez uložení"])}
        }
      },
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
      "with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])}
    }
  },
  "onboarding": {
    "welcome": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vítejte v MAIT chatu!"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Než začneme, potřebovali bychom od Vás povolení k některým službám, aby aplikace fungovala správně."])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojďme začít"])}
    }
  },
  "countryCodes": {
    "afghanistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghánistán"])},
    "albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albánie"])},
    "algeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alžírsko"])},
    "samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Americká Samoa"])},
    "the american virgin islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Americké Panenské ostrovy"])},
    "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "angola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "antigua and barbuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua a Barbuda"])},
    "anguilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
    "argentina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "armenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arménie"])},
    "aruba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
    "ascension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascension"])},
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austrálie"])},
    "australian territories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australská teritoria"])},
    "azerbaijan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ázerbájdžán"])},
    "bahrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrajn"])},
    "bahamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamy"])},
    "bangladesh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladéš"])},
    "barbados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgie"])},
    "belize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "belarus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bělorusko"])},
    "benin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "bermuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermudy"])},
    "bhutan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhútán"])},
    "bolivia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolívie"])},
    "bosnia and herzegovina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosna a Hercegovina"])},
    "botswana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "brazil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazílie"])},
    "virgin islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britské Panenské ostrovy"])},
    "brune": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunej"])},
    "bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulharsko"])},
    "burkina faso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "burundi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "cook islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookovy ostrovy"])},
    "cad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čad"])},
    "montenegro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Černá Hora"])},
    "czech republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česká republika"])},
    "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čína"])},
    "denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dánsko"])},
    "democratic republic of congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demokratická republika Kongo"])},
    "diego garcia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diego García"])},
    "dominica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominika"])},
    "dominican republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominikánská republika"])},
    "djibout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Džibutsko"])},
    "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
    "ecuador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekvádor"])},
    "eritrea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonsko"])},
    "ethiopia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiopie"])},
    "faroe islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faerské ostrovy"])},
    "fiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fidži"])},
    "philippines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filipíny"])},
    "finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finsko"])},
    "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francie"])},
    "french guiana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francouzská Guyana"])},
    "french polynesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francouzská Polynésie"])},
    "gabon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "gambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambie"])},
    "ghana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "gibraltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
    "grenada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "greenland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grónsko"])},
    "georgia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruzie"])},
    "guadeloupe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
    "guam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "guatemala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "guinea-bissau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "guyana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "haiti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "honduras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "hong kong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongkong"])},
    "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chorvatsko"])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indie"])},
    "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonésie"])},
    "iraq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irák"])},
    "iran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írán"])},
    "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irsko"])},
    "iceland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Island"])},
    "italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itálie"])},
    "israel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izrael"])},
    "jamaica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamajka"])},
    "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonsko"])},
    "yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jemen"])},
    "south african republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jihoafrická republika"])},
    "south korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jižní Korea"])},
    "cayman islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kajmanské ostrovy"])},
    "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordánsko"])},
    "cambodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kambodža"])},
    "cameroon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamerun"])},
    "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada"])},
    "kazakhstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazachstán"])},
    "kenya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keňa"])},
    "kiribati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolumbie"])},
    "congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kongo"])},
    "costa rica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostarika"])},
    "cuba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuba"])},
    "kuwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvajt"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kypr"])},
    "kyrgyzstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstán"])},
    "laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "lesotho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "lebanon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libanon"])},
    "liberia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libérie"])},
    "libya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libye"])},
    "lichtenstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lichtenštejnsko"])},
    "lithuanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litva"])},
    "latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lotyšsko"])},
    "luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucembursko"])},
    "madagascar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagaskar"])},
    "hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maďarsko"])},
    "macedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makedonie"])},
    "malaysia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malajsie"])},
    "malawi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "maldives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maledivy"])},
    "mali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "morocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maroko"])},
    "marshall islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshallovy ostrovy"])},
    "martinik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinik"])},
    "mauritius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauricius"])},
    "mauritania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritánie"])},
    "mexico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexiko"])},
    "moldova": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldavsko"])},
    "micronésia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikronésie"])},
    "monaco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monako"])},
    "mongolia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolsko"])},
    "montserrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "mosambique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mosambik"])},
    "myanmar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar"])},
    "namibia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibie"])},
    "nura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Německo"])},
    "nepal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepál"])},
    "niger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "nicaragua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikaragua"])},
    "nigeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigérie"])},
    "niue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "netherlands antilles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nizozemské Antily"])},
    "netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nizozemsko"])},
    "norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norsko"])},
    "new caledonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová Kaledonie"])},
    "oman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omán"])},
    "new zealand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový Zéland"])},
    "pakistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pákistán"])},
    "palau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "palestine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palestina"])},
    "panama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "paraguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "ivory coast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobřeží slonoviny"])},
    "poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polsko"])},
    "puerto rico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portoriko"])},
    "portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugalsko"])},
    "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakousko"])},
    "equatorial guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rovníková Guinea"])},
    "romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumunsko"])},
    "rwanda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "saint pierre and miquelon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Pierre a Miquelon"])},
    "greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řecko"])},
    "salvador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvador"])},
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "san marino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "saudi arabia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saúdská Arábie"])},
    "senegal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "north korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severní Korea"])},
    "seychelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychely"])},
    "sierra leone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapur"])},
    "slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovensko"])},
    "slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovinsko"])},
    "somalia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somálsko"])},
    "united arab emirates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojené arabské emiráty"])},
    "united kingdom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojené království"])},
    "serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Srbsko"])},
    "sri lanka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Srí Lanka"])},
    "central african republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Středoafrická republika"])},
    "sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súdán"])},
    "saint helena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svatá Helena"])},
    "saint lucia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svatá Lucie"])},
    "saint kitts and nevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svatý Kryštof a Nevis"])},
    "saint thomas and prince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svatý Tomáš a Princův ostrov"])},
    "saint vincent and the grenadines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svatý Vincenc a Grenadiny"])},
    "syria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sýrie"])},
    "solomon islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šalamounovy ostrovy"])},
    "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Španělsko"])},
    "sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Švédsko"])},
    "switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Švýcarsko"])},
    "tajikistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tádžikistán"])},
    "tanzania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzanie"])},
    "thailand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thajsko"])},
    "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchaj-wan"])},
    "togo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "tonga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "trinidad and tobago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad a Tobago"])},
    "tunisia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisko"])},
    "turkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turecko"])},
    "turkmenistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistán"])},
    "tuvalu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "uganda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrajina"])},
    "uruguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "uzbekistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistán"])},
    "vanuatu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "venezuela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "vietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "east timor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Východní Timor"])},
    "wallis and futuna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis a Futuna"])},
    "zambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambie"])},
    "zimbabwe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])},
    "american samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Americká Samoa"])},
    "british indian ocean territory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britské indickooceánské území"])},
    "falkland islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falklandy"])},
    "guernsey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "isle of man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostrov Man"])},
    "jersey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "cape verde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapverdy"])},
    "qatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katar"])},
    "christmas island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vánoční ostrov"])},
    "cocos island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokosový ostrov"])},
    "kosovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
    "comoros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komorské ostrovy"])},
    "macau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macao"])},
    "mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
    "papua new guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua Nová Guinea"])},
    "pitcairn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairn"])},
    "reunion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "russia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusko"])},
    "northern mariana islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severní Mariany"])},
    "south sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jižní Súdán"])},
    "suriname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "saint barthelemy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svatý Bartoloměj"])},
    "saint martin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svatý Martin"])},
    "swaziland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svazijsko"])},
    "svalbard and jan mayen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Špicberky a Jan Mayen"])},
    "tokelau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "turks and caicos islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks a Caicos"])},
    "united states of america": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojené státy americké"])},
    "vatican": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatikán"])},
    "western sahara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Západní Sahara"])},
    "falkland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falklandy"])},
    "capsule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapverdy"])},
    "catarrh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katar"])},
    "chamber and mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komory a Mayotte"])},
    "macao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macao"])},
    "papua-nova guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua-Nová Guinea"])},
    "réunion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réunion"])},
    "northern marian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Severní Mariany"])},
    "surinam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surinam"])},
    "savilia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svazijsko"])},
    "tokelu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "turks and caicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks a Caicos"])}
  },
  "versions": {
    "whatIsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co je nového v MAIT Chatu"])},
    "registrationOverPhone": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrace přes telefonní číslo"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rychlá registrace bez zbytečných průtahů. Nově stačí pro registraci do MAIT jen telefonní číslo."])}
    },
    "communicationWithPhoneContacts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunikace s mobilními kontakty"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buďte v kontaktu i s přáteli, které máte uloženy v telefonu, ale nemají MAIT účet."])}
    },
    "improvedVideoDisplaying": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vylepšené zobrazování videí"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledujte pohodlně nahraná videa. Přidali jsme fullscreen režim pro videa na šířku i na výšku."])}
    },
    "callWithYourFriends": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volejte si se svými přáteli"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIT Chat nyní podporuje audiohovory a videohovory s vašimi přáteli v síti MAIT."])}
    },
    "groupCalls": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupinové hovory a videohovory"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volat si v MAIT Chat můžete nyní i v rámci skupinových konverzací."])}
    },
    "improvementsAndFixes": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vylepšení a opravy"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pod kapotou jsme opravili celou řadu chyb a přidali různá vylepšení pro lepší zážitek."])}
    }
  },
  "message-left-sidebar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
    "search-bar-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komu"])}
  },
  "message-header": {
    "header-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komu"])}
  },
  "user-list": {
    "multiselect-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte"])}
  },
  "share": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sdílet"])}
  }
}