<template>
  <div v-if="isInputShown" class="chat-input-bar">
    <div v-if="!isRecordingOrFinished" class="handlers">
      <Icon
        v-if="!isMobile"
        :icon="IconType.EMOJI"
        alt="emoji"
        class="emoji-btn"
        @click="toggleShowEmoji"
      />
      <InputAttachment @add-attachment="addAttachment" />
      <AudioInput @start-recording="startRecording" />
    </div>
    <Icon
      v-else
      :icon="IconType.TRASH"
      alt="trash-icon"
      class="clear-btn"
      @click="clearRecording"
    />
    <div v-if="!isRecordingOrFinished" class="middle-wrapper">
      <EmojisAndGiphs
        v-if="data.isEmojiShown"
        @insert-emoji="addMessageText"
        @toggle-emoji-list-visibility="toggleShowEmoji"
        @insert-gif="addGifAttachment"
      />
      <div
        class="input-wrapper"
        :style="{ height: `${messageInputHeight - 16}px` }"
      >
        <div class="input-inner-wrapper">
          <InputAttachmentWithPreview
            :attachments="data.attachments"
            @add-attachment="addAttachment"
            @remove-attachment="removeAttachment"
          />
          <InputText
            ref="messageInput"
            :text="data.messageText"
            :attachments="data.attachments"
            @update-text="setMessageText"
            @send-message="sendMessage"
          />
        </div>
        <Icon
          v-if="isMobile"
          :icon="IconType.EMOJI"
          alt="emoji"
          class="emoji-btn"
          @click="toggleShowEmoji"
        />
      </div>
    </div>
    <div
      v-if="audioPlayerProps.isShown"
      id="audioPlayerRef"
      class="audio-recorder-container"
    >
      <AudioPlayer v-bind="audioPlayerProps" />
    </div>
    <Icon :icon="IconType.SEND" class="icon-send" @click="sendMessage" />
  </div>
</template>

<script setup lang="ts">
import InputText from "@/components/chat/chatContainer/InputText/InputText.vue";
import InputAttachment from "@/components/chat/chatContainer/InputAttachment.vue";
import InputAttachmentWithPreview from "@/components/chat/chatContainer/InputAttachmentWithPreview.vue";
import { Profile } from "@/store/auth/state";
import { useGetChatInputBarProperties } from "./useGetChatInputBarProperties";
import { User } from "@/store/users/state";
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";
import AudioInput from "../AudioInput.vue";
import AudioPlayer from "../AudioPlayer/AudioPlayer.vue";
import EmojisAndGiphs from "@/components/cards/EmojisAndGiphs/EmojisAndGiphs.vue";

interface Props {
  newChat?: boolean;
  alreadyExistsChatId?: string | null;
  members?: Profile[] | User[];
}

const props = withDefaults(defineProps<Props>(), {
  newChat: false,
  alreadyExistsChatId: "",
  members: () => [],
});

const {
  data,
  messageInput,
  isInputShown,
  isMobile,
  messageInputHeight,
  toggleShowEmoji,
  addAttachment,
  addMessageText,
  removeAttachment,
  setMessageText,
  sendMessage,
  addGifAttachment,
  // audio recorder handlers
  startRecording,
  clearRecording,
  audioPlayerProps,
  isRecordingOrFinished,
} = useGetChatInputBarProperties(props);
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatInputBar.scss";
</style>
