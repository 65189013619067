import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js";
// @ts-expect-error
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import { AudioRecordingData } from "../ChatInputBar/ChatInputBarTypes";
import { getFormattedTime } from "@/utils/modifiers";
import { getWaveSurferOptions } from "./getWaveSurferOptions";
import { IconType } from "@/types/icons";
import { getAudioPlayerIcon } from "./getAudioPlayerIcon";
import { getDefaultRecordingData } from "./getDefaultRecordingData";
import { postWebviewMessage } from "@/utils/api";
import { BridgeActionEnum, BridgeStatusEnum } from "@/types/webview";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { AppEnvironmentEnum } from "@/store/app/state";
import { getApplicationEnvironment } from "@/utils/app";

const defaultRecordingFileName = "recording.webm";

interface UseAudioPlayerArgs {
  containerId: string;
  isRecorder?: boolean;
  url?: string;
  isMine?: boolean;
  duration?: string;
}

export const useAudioPlayer = ({
  containerId,
  isRecorder,
  url,
  isMine = false,
  duration,
}: UseAudioPlayerArgs) => {
  const waveSurfer = ref<WaveSurfer | null>(null);
  const record = ref<any>(null);
  const recordingData = reactive<AudioRecordingData>(
    getDefaultRecordingData(duration),
  );
  const getDefaultControlIcon = () =>
    getAudioPlayerIcon({ isMine, isPaused: !isRecorder });
  const controlIcon = ref<IconType>(getDefaultControlIcon());
  const restoreDefaults = (shouldClear = false) => {
    Object.assign(recordingData, {
      ...getDefaultRecordingData(duration),
      shouldClear,
    });
    controlIcon.value = getDefaultControlIcon();
  };
  const microphoneStatus = useComputedValue<string | null>(
    GetterTypes.GET_MICROPHONE_STATUS,
  );
  const isAndroid = getApplicationEnvironment() === AppEnvironmentEnum.Android;

  const defaultWaveSurferOptions = getWaveSurferOptions({
    isRecorder,
    isMine,
    containerId,
    url,
  });
  const waveSurferOptions = ref<WaveSurferOptions>(defaultWaveSurferOptions);

  const destroyWavesurfer = () => {
    const wave = waveSurfer.value;
    if (!wave) {
      return;
    }
    wave.destroy();
  };

  const createWaveSurfer = () => {
    destroyWavesurfer();
    waveSurferOptions.value = defaultWaveSurferOptions;
    waveSurfer.value = WaveSurfer.create(defaultWaveSurferOptions);

    waveSurfer.value.on("play", () => {
      controlIcon.value = getAudioPlayerIcon({ isMine, isPaused: false });
      recordingData.hasPlayed = true;
    });
    waveSurfer.value.on("pause", () => {
      controlIcon.value = getAudioPlayerIcon({ isMine, isPaused: true });
    });

    waveSurfer.value.on("audioprocess", () => {
      if (!waveSurfer.value) {
        return;
      }
      const currentTimeMs = waveSurfer.value.getCurrentTime() * 1000;

      updateCurrentTime(currentTimeMs);
    });

    waveSurfer.value.on("seeking", () => {
      const player = waveSurfer.value;
      if (!player) {
        return;
      }
      player.play();
    });
    waveSurfer.value.on("interaction", (currentTimeSeconds) => {
      const player = waveSurfer.value;
      if (!player) {
        return;
      }
      updateCurrentTime(currentTimeSeconds * 1000);
    });
    waveSurfer.value.on("dragstart", () => {
      const player = waveSurfer.value;
      if (!player) {
        return;
      }
      player.pause();
    });

    if (!isRecorder) {
      return;
    }

    initializeRecorder();
  };

  const initializeRecorder = () => {
    if (!waveSurfer.value) {
      return;
    }
    record.value = waveSurfer.value.registerPlugin(
      RecordPlugin.create({ renderRecordedAudio: true }),
    );
    addRecorderListeners();
  };

  const addRecorderListeners = () => {
    record.value.on("record-end", (blob: Blob) => {
      if (recordingData.shouldClear) {
        clearAllPlugins();
        recordingData.shouldClear = false;
        return;
      }
      clearAllPlugins();
      const newOptions = {
        ...getWaveSurferOptions({
          isRecorder: false,
          isMine: true,
          containerId,
        }),
        // keep the width updated from the resize handler
        width: waveSurferOptions.value.width,
      };
      waveSurferOptions.value = newOptions;
      waveSurfer.value?.setOptions(newOptions);
      controlIcon.value = getAudioPlayerIcon({ isMine, isPaused: true });
      const audioFile = new File([blob], defaultRecordingFileName, {
        type: blob.type,
      });

      recordingData.recordingFile = audioFile;
      recordingData.isRecording = false;
      recordingData.isFinished = true;
    });

    record.value.on("record-progress", (time: number) => {
      updateCurrentTime(time);
      updateTotalTime(time);
    });
    record.value.on("record-start", () => {
      recordingData.isRecording = true;
    });
  };

  const updateCurrentTime = (time: number) => {
    recordingData.currentTime = getFormattedTime(time);
  };
  const updateTotalTime = (time: number) => {
    recordingData.totalTime = getFormattedTime(time);
  };

  const clearAllPlugins = () => {
    if (!waveSurfer.value) {
      return;
    }
    waveSurfer.value.getActivePlugins().forEach((plugin) => {
      plugin.destroy();
    });
  };

  const clearRecording = async () => {
    restoreDefaults(recordingData.isRecording);
    // if the recording is playing, stop it
    if (waveSurfer.value?.isPlaying()) {
      waveSurfer.value?.stop();
    }
    await stopRecording();
  };

  const pauseRecording = () => {
    const rec = record.value;
    if (!rec) {
      return;
    }

    if (rec.isPaused()) {
      rec.resumeRecording();
      return;
    }

    rec.pauseRecording();
  };

  const playPauseRecording = async () => {
    const player = waveSurfer.value;
    if (!player) {
      return;
    }

    const rec = record.value;

    if (rec?.isRecording()) {
      await stopRecording();
      return;
    }

    player.playPause();
  };

  const startRecording = async () => {
    recordingData.isRecording = true;
  };

  const initializeRecording = async () => {
    const rec = record.value;
    if (!rec) {
      return;
    }
    if (isAndroid && microphoneStatus.value !== BridgeStatusEnum.Enabled) {
      postWebviewMessage({ action: BridgeActionEnum.EnableMicrophone });
      return;
    }
    await rec.startRecording();
  };

  const stopRecording = async () => {
    const rec = record.value;
    if (!rec) {
      return;
    }
    const isRecordingOrPaused = rec.isRecording() || rec.isPaused();
    if (!isRecordingOrPaused) {
      return;
    }
    await rec.stopRecording();
  };

  const isRecordingOrFinished = computed(() =>
    Boolean(
      (!isRecorder && url) ||
        recordingData.recordingFile ||
        recordingData.isRecording ||
        recordingData.isFinished,
    ),
  );

  const clearEventListeners = () => {
    const wave = waveSurfer.value;
    if (!wave) {
      return;
    }
    wave.unAll();
  };

  onMounted(() => {
    if (isRecorder) {
      return;
    }
    createWaveSurfer();
  });

  const refreshContainerWidth = () => {
    const wave = waveSurfer.value;
    if (!wave) {
      return;
    }
    const container = document.getElementById("audioPlayerRef");
    if (!container) {
      return;
    }

    const containerWidth = container.clientWidth;

    const newOptions = {
      ...waveSurferOptions.value,
      width: containerWidth - 112,
    };
    waveSurferOptions.value = newOptions;
    // @ts-expect-error
    wave.setOptions(newOptions);
  };

  onUnmounted(() => {
    clearAllPlugins();
    clearEventListeners();
    window.removeEventListener("resize", refreshContainerWidth);
  });

  watch(
    () => recordingData.isRecording,
    async (isRecording, oldIsRecording) => {
      if (!isRecording || oldIsRecording === isRecording) {
        return;
      }
      await nextTick();
      createWaveSurfer();
      initializeRecording();
      refreshContainerWidth();
      window.addEventListener("resize", refreshContainerWidth);
    },
  );

  const audioPlayerProps = computed(() => {
    const displayTime = recordingData.hasPlayed
      ? recordingData.currentTime
      : recordingData.totalTime;

    return {
      isShown: isRecordingOrFinished.value,
      playPauseRecording,
      displayTime,
      containerId,
      icon: controlIcon.value,
      isMine,
    };
  });

  return {
    startRecording,
    pauseRecording,
    stopRecording,
    playPauseRecording,
    clearRecording,
    isRecordingOrFinished,
    recordingData,
    audioPlayerProps,
    waveSurfer,
  };
};
